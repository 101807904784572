import { useProductsStore } from '~/store/productsStore.js'

const productStore = useProductsStore()

// child middleware of shopDiamond middleware in legacy code
// use for diamond item-diamond-details, diamond-details
export default defineNuxtRouteMiddleware(async (to, from) => {
  if (!process.client) return
  if (to.query.opened) {
    let opened = await productStore.getSelectedDiamonds({
      ...to.query,
      diamond: to.query.opened,
    })
    if (to.query.opened != to.query.diamond) {
      productStore.getSelectedDiamonds({
        ...to.query,
        diamond: to.query.diamond,
      })
    }

    productStore.setSelectedDiamonds({
      selectedDiamonds: opened,
      query: {
        ...to.query,
        isDefault: false,
      },
    })
  } else {
    let newQuery = to.query
    newQuery.diamond = null
    newQuery.opened = null
    newQuery.page = null

    await navigateTo({ path: to.path, query: newQuery })
  }
})
